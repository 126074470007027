import {
	addBins,
	getBins,
	getBinsByID,
	updateBins,
	searchBins,
	getBinsForMap,
} from "../API/bins.api";

import axiosCall from "../API/axios";
const getAllBins = (limit, offset) => {
	const res = axiosCall(
		`${getBins}?limit=${limit}&offset=${offset}`,
		"",
		"GET"
	);

	return res;
};
const getAllForMap = (limit, offset) => {
	const res = axiosCall(`${getBinsForMap}`, "", "GET");
	return res;
};

const Addbins = (data) => {
	return axiosCall(addBins, data, "POST");
};
const Updatebins = (data) => {
	return axiosCall(updateBins, data, "POST");
};
const binsGetById = (data) => {
	let url = `${getBinsByID}/${data}`;
	return axiosCall(url, "", "GET");
};
const searchBin = (seach) => {
	return axiosCall(`${searchBins}?${seach}`, "", "GET");
};
export default {
	getAllBins,
	Addbins,
	Updatebins,
	binsGetById,
	searchBin,
	getAllForMap,
};
