import axios from "axios";
import Logout from "src/views/pages/logout/Logout";
const apiCall = async (url, data, method) => {
	try {
		let token = JSON.parse(localStorage.getItem("retailerAdmin"));
		var config;
		if (token) {
			config = {
				method,
				url,
				headers: {
					Authorization: `Bearer ${token.token}`,
					"Content-Type": "application/json",
				},
				data: data,
			};
		} else {
			config = {
				method,
				url,
				headers: {
					"Content-Type": "application/json",
				},
				data: data,
			};
		}
		return await axios(config);
	} catch (err) {
		if (err?.response?.statusText === "Unauthorized") {
			localStorage.clear();
			window.location.href = "/#/login";
			window.location.reload();
		}
	}
};

export default apiCall;
