import { GetTransactionsByUser } from "src/API/transactions.api";
import { GetAllUser, UpdateUserr } from "src/API/user.api";
import {
  changepassword,
  GetUserById,
  ImageUpload,
  UpdateUser,
  SearchUser,
} from "../API/admin.api";
import axiosCall from "../API/axios";

const getUserById = (id) => {
  return axiosCall(`${GetUserById}/${id}`, "", "GET");
};

const getAllUser = () => {
  return axiosCall(GetAllUser);
};

const getAllUserPagination = (limit, offset) => {
  return axiosCall(`${GetAllUser}?limit=${limit}&offset=${offset}`);
};

const imageUpload = (data) => {
  return axiosCall(ImageUpload, data, "POST");
};

const userUpdate = (data) => {
  return axiosCall(UpdateUser, data, "POST");
};

const changePassword = (data) => {
  return axiosCall(changepassword, data, "POST");
};

const searchUser = (seach) => {
  return axiosCall(`${SearchUser}?${seach}`, "", "GET");
};

const statusUpdate = (data) => {
  return axiosCall(`${UpdateUserr}`, data, "POST");
};

const getAllUserTransaction = (id, limit, offset) => {
  return axiosCall(
    `${GetTransactionsByUser}/${id}?limit=${limit}&offset=${offset}`,
    "",
    "GET"
  );
};

export default {
  getAllUser,
  getAllUserPagination,
  getUserById,
  imageUpload,
  userUpdate,
  changePassword,
  searchUser,
  statusUpdate,
  getAllUserTransaction,
};
