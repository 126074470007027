import { CategoryService } from "../../service";
const initialState = {
  data: [],
};

const getItem = async (state = initialState, action) => {
  switch (action.type) {
    case "GET_CATEGORY_ITEM_DATA":
      const res = await CategoryService.getAllCategory();
      return {
        ...state,
        data: res.data.data,
      };

    default:
      return state;
  }
};

export default getItem;
