import {
	addBanner,
	getBanner,
	updateBanner,
	deleteBanner,
	getBannerByID,
} from "../API/banner.api";

import axiosCall from "../API/axios";
import store from "src/store";
import axios from "axios";
const getAllBannersPagination = async (offset, limit) => {
	return axiosCall(`${getBanner}?offset=${offset}&limit=${limit}`, "", "GET");
};
const getAllBanners = () => {
	const res = axiosCall(getBanner, "", "GET");
	if (res) {
		store.dispatch({
			type: "FETCH_CATEGORY_SUCCESS",
			payload: res.data,
		});
	}
	return res;
};
const AddBanners = (data) => {
	return axiosCall(addBanner, data, "POST");
};
const UpdateBanners = (id, data) => {
	let url = `${process.env.REACT_APP_API_URL}banner/update/${id}`;
	return axiosCall(url, data, "POST");
};
const bannersByID = (data) => {
	let url = `${getBannerByID}/${data}`;
	return axiosCall(url, "", "GET");
};
const deleteBannerByID = async (data) => {
	let url = `${deleteBanner}/${data}`;
	let token = JSON.parse(localStorage.getItem("retailerAdmin"));
	const config = {
		method: "post",
		url: url,
		headers: {
			Authorization: `Bearer ${token.token}`,
		},
	};

	return await axios(config);
};
export default {
	AddBanners,
	UpdateBanners,
	bannersByID,
	getAllBanners,
	deleteBannerByID,
	getAllBannersPagination,
};
