import {
  GetAllMrfAdmin,
  GetMrfCommunities,
  GetMrfEmployees,
  SearchMrfAdmin,
} from "../API/mrfAdmin.api";
import axiosCall from "../API/axios";

const getAllMrfAdmin = () => {
  return axiosCall(GetAllMrfAdmin);
};

const getAllMrfAdminPagination = (limit, offset) => {
  return axiosCall(`${GetAllMrfAdmin}?limit=${limit}&offset=${offset}`);
};

const searchMrfAdmin = (seach) => {
  return axiosCall(`${SearchMrfAdmin}?${seach}`, "", "GET");
};

const getMrfEmployyesPagination = (mrfId, limit, offset) => {
  return axiosCall(
    `${GetMrfEmployees}/${mrfId}?limit=${limit}&offset=${offset}`
  );
};

const getMrfCommunityPagination = (mrfId, limit, offset) => {
  return axiosCall(
    `${GetMrfCommunities}/${mrfId}?limit=${limit}&offset=${offset}`
  );
};

export default {
  getAllMrfAdmin,
  searchMrfAdmin,
  getAllMrfAdminPagination,
  getMrfEmployyesPagination,
  getMrfCommunityPagination,
};
