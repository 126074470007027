import {
  getCategory,
  addCategory,
  updateCategory,
  getCategoryByID,
  searchCategory,
} from "../API/category";

import axiosCall from "../API/axios";
import store from "src/store";

const getAllCategory = () => {
  const res = axiosCall(getCategory, "", "GET");
  if (res) {
    store.dispatch({
      type: "FETCH_CATEGORY_SUCCESS",
      payload: res.data,
    });
  }
  return res;
};

const getAllCategoryPagination = (perPage, currentPage, newCat) => {
  const res = axiosCall(
    `${getCategory}?is_new=${newCat}&limit=${perPage}&offset=${currentPage}`,
    "",
    "GET"
  );
  if (res) {
    store.dispatch({
      type: "FETCH_CATEGORY_SUCCESS",
      payload: res.data,
    });
  }
  return res;
};

const AddCategory = (data) => {
  return axiosCall(addCategory, data, "POST");
};

const UpdateCategory = (data) => {
  return axiosCall(updateCategory, data, "POST");
};

const categoryGetById = (data) => {
  let url = `${getCategoryByID}/${data}`;
  return axiosCall(url, "", "GET");
};

const Searchcategory = (seach) => {
  return axiosCall(`${searchCategory}?${seach}`, "", "GET");
};

export default {
  getAllCategory,
  getAllCategoryPagination,
  AddCategory,
  UpdateCategory,
  categoryGetById,
  Searchcategory,
};
