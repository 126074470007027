import {
  GetAllRetailerAdmin,
  UpdateRetailerAdmin,
  SearchRetailerAdmin,
  GetAllRetailerAdminOnCampus,
  GetAllRetailerEmployees,
} from "../API/retailerAdmin.api";
import axiosCall from "../API/axios";

const getAllRetailerAdmin = () => {
  return axiosCall(GetAllRetailerAdmin);
};

const getAllRetailerAdminPagination = (limit, offset) => {
  return axiosCall(`${GetAllRetailerAdmin}?limit=${limit}&offset=${offset}`);
};

const getRetailerEmployeesPagination = (id, limit, offset) => {
  return axiosCall(
    `${GetAllRetailerEmployees}/${id}?limit=${limit}&offset=${offset}`
  );
};

const getAllRetailerAdminOnCampus = () => {
  return axiosCall(GetAllRetailerAdminOnCampus);
};

const getAllRetailerAdminOnCampusPagination = (limit, offset) => {
  return axiosCall(
    `${GetAllRetailerAdminOnCampus}?limit=${limit}&offset=${offset}`
  );
};

const searchRetailerAdmin = (seach, oncampus) => {
  if (oncampus) {
    return axiosCall(
      `${SearchRetailerAdmin}?onCampus=${true}&${seach}`,
      "",
      "GET"
    );
  } else {
    return axiosCall(`${SearchRetailerAdmin}?${seach}`, "", "GET");
  }
};

export default {
  getAllRetailerAdmin,
  getAllRetailerAdminPagination,
  searchRetailerAdmin,
  getAllRetailerAdminOnCampus,
  getAllRetailerAdminOnCampusPagination,
  getRetailerEmployeesPagination,
};
