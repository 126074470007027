import {
  CreateGiveaway,
  UpdateGiveaway,
  DeleteGiveaway,
  GetAllGiveaway,
  GetGiveawayById,
} from "../API/giveaway.api";

import axiosCall from "../API/axios";
const getAllGiveaways = () => {
  const res = axiosCall(GetAllGiveaway, "", "GET");

  return res;
};
const AddGiveaways = (data) => {
  return axiosCall(CreateGiveaway, data, "POST");
};
const UpdateGiveaways = (data) => {
  return axiosCall(UpdateGiveaway, data, "POST");
};
const GiveawaysGetById = (data) => {
  let url = `${GetGiveawayById}/${data}`;
  return axiosCall(url, "", "GET");
};
const searchBin = (seach) => {
  return axiosCall(`${Searchgiveaways}?${seach}`, "", "GET");
};
export default {
  getAllGiveaways,
  AddGiveaways,
  UpdateGiveaways,
  GiveawaysGetById,
  searchBin,
};
