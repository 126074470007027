import {
  SignIn,
  FORGOTPASSWORD,
  RESETPASSWORD,
  SignUp,
  VerifyEmail,
} from "../API/auth.api";
import axiosCall from "../API/axios";

const loggedInUser = (data) => {
  return axiosCall(SignIn, data, "POST");
};
const forgotPassword = (data) => {
  return axiosCall(FORGOTPASSWORD, data, "POST");
};
const resetPassword = (data) => {
  return axiosCall(RESETPASSWORD, data, "POST");
};

const registerUser = (data) => {
  return axiosCall(SignUp, data, "POST");
};
const verifyEmail = (data) => {
  return axiosCall(VerifyEmail, data, "POST");
};

export default {
  loggedInUser,
  forgotPassword,
  resetPassword,
  registerUser,
  verifyEmail,
};
