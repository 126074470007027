import {
  getitem,
  addItem,
  updateItem,
  getItemById,
  searchItem,
} from "../API/item";
import axiosCall from "../API/axios";

const getAllItem = () => {
  return axiosCall(getitem, "", "GET");
};

const addItems = (data) => {
  return axiosCall(addItem, data, "POST");
};

const updateItems = (data) => {
  return axiosCall(updateItem, data, "POST");
};

const getCategoryItemById = (id) => {
  let url = `${getItemById}/${id}`;
  return axiosCall(url, "", "GET");
};

const SearchItem = (seach) => {
  return axiosCall(`${searchItem}?${seach}`, "", "GET");
};

const getAllItemPagination = async (offset, limit, arrived) => {
  if (arrived) {
    return axiosCall(
      `${getitem}?offset=${offset}&limit=${limit}&account_status=arrived`,
      "",
      "GET"
    );
  } else {
    return axiosCall(`${getitem}?offset=${offset}&limit=${limit}`, "", "GET");
  }
};

export default {
  updateItems,
  getCategoryItemById,
  addItems,
  getAllItem,
  SearchItem,
  getAllItemPagination,
};
